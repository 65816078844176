var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"D4-1cJOP8_kzDgWKL3qwS"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import SentryRRWeb from '@sentry/rrweb';
import { CaptureConsole } from '@sentry/integrations';
import { IgnoreIntegrationErrors } from '@/sentry.integration.ignore';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN)
    Sentry.init({
        dsn:
            SENTRY_DSN ||
            'https://fb83546d338847d68be01e9406d9680f@o4504254269882368.ingest.sentry.io/4504254272765952',
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
        integrations: [
            new IgnoreIntegrationErrors(),
            new CaptureConsole({
                // array of methods that should be captured
                // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['error'],
            }),
            new SentryRRWeb({
                // default is 5 minutes
                checkoutEveryNms: 1 * 60 * 1000,
                // on by default
                maskAllInputs: false,
                // don't attach recordings to transactions
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore-next-line
                errorsOnly: false,
            }),
        ],
    });
